<template>
  <div id="app">
    <nav
      class="
        navbar navbar-expand-lg navbar-light
        bg-light
        gfu-navbar
        pb-0
        headroom headroom--not-bottom headroom--pinned headroom--top
      "
    >
      <div class="container-fluid">
        <div class="d-flex mr-auto">
          <a class="navbar-brand" href="https://www.gfu.net"
            ><span class="sr-only">GFU Cyrus AG</span>
            <img src="https://www.gfu.net/images/logo.png" style="max-width: 70px">
          </a>
          <div class="navbar-slogan">
            Ihr Partner für IT-Schulungen.<br />Seit über 40 Jahren.
          </div>
        </div>
      </div>
    </nav>
    <section class="jumbotron jumbotron-gradientBG mt-8">
      <div class="gradient"></div>
      <div class="container-fluid">
        <div class="row">
          <div
            class="col-md-10 offset-md-1 col-lg-8 offset-lg-2 text-center"
            style="position: relative"
          >
            <h1 class="h1 text-light mt-3 mb-0">
              Adresse für die Zusendung Ihrer Seminarunterlagen
            </h1>
          </div>
        </div>
      </div>
    </section>
    <b-container class="main-content">
      <AddressForm />
    </b-container>
    <footer class="footer">
      <div class="container">
        <b-row>
          <b-col md="5" class="offset-md-1 col-lg-4 offset-lg-2"
            >{{dateNow}}</b-col
          >
          <b-col md="5" class="text-right col-lg-4 text-right">
            GFU Cyrus AG |
            <a href="https://www.gfu.net/impressum.html" data-gtm-imprint=""
              >Impressum</a
            >
            |
            <a href="https://www.gfu.net/datenschutz.html" data-gtm-agb=""
              >Datenschutz</a
            >
          </b-col>
        </b-row>
      </div>
    </footer>
  </div>
</template>

<script>
import AddressForm from "./components/AddressForm";

export default {
  name: "App",
  components: {
    AddressForm,
  },
  computed: {
    dateNow() {
      const now = new Date();
      return now.toLocaleDateString('de-DE', {
        year: 'numeric',
        day: '2-digit',
        month: '2-digit',
      })
    }
  }
};
</script>

<style>
.jumbotron-gradientBG {
  position: relative;
  background-image: url("https://gfu.net/images/v2/teilnehmer-rezeption.jpg");
  padding-top: 10rem !important;
  padding-bottom: 15rem !important;
  margin-bottom: -10rem !important;
  background-position: top 6% center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  background-color: #fff;
}
.jumbotron-gradientBG .gradient {
  background: linear-gradient(
    to bottom,
    rgba(38, 107, 179, 0.7) 50%,
    #fff 100%
  );
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  width: 100%;
  height: 100%;
}
.jumbotron-gradientBG .container-fluid {
  z-index: 10;
}
.main-content {
  z-index: 99;
  padding-top: 0 !important;
}
.text-light {
  color: #f8f9fa !important;
}
.card {
  box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%);
}
.card .card-header {
  background: 0 0;
  border-bottom: 1px solid #ced4da;
  padding: 1.55em 1.55em 0 1.55em;
}
.card .card-body {
  padding: 1.55em;
}
.footer {
  background: #266cb4;
  padding-top: 20px;
  padding-bottom: 20px;
  color: hsla(0, 0%, 100%, 0.7) !important;
  margin-top: 40px;
}

.footer a,
.footer a:active,
.footer a:visited {
  color: white !important;
  text-decoration: none;
}
.text-right {
  text-align: right !important;
}

/*! CSS Used from: https://www.gfu.net/css/styles2.css?v=1479bbcdbf379ce87b4ee36bc5c41a94 */
.fa {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.pull-right {
  float: right;
}
.fa-search:before {
  content: "";
}
.fa-phone:before {
  content: "";
}
.fa-phone-square:before {
  content: "";
}
.fa-caret-down:before {
  content: "";
}
.fa-sort-alpha-asc:before {
  content: "";
}
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}
.logo {
  fill: #266cb4;
  height: 50px;
}
.gfu-navbar {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  transition: box-shadow 0.3s ease-in-out, transform 0.3s ease-in-out;
  will-change: transform;
}
.navbar-slogan {
  font-size: 0.8rem;
  line-height: 1.2;
  position: absolute;
  margin: 0.3rem 0 0 5.4rem;
  color: #6c757d !important;
}
@media (max-width: 991.98px) {
  .navbar-slogan {
    margin-left: 4.4rem;
  }
}
@media (max-width: 575.98px) {
  .navbar-slogan {
    display: none;
  }
}
@media (max-width: 991.98px) {
  .navbar .navbar-collapse {
    background-color: #e9ecef;
    margin-left: -1rem;
    margin-right: -1rem;
  }
  .navbar .nav {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
  .navbar .logo {
    width: auto;
    height: 40px;
  }
}
@media (min-width: 768px) {
  .navbar .nav {
    margin-right: -1rem;
  }
}
@media (min-width: 992px) {
  .navbar .navbar-brand {
    margin-bottom: 0.5rem;
  }
}
.navbar .nav-link {
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (max-width: 991.98px) {
  .navbar .nav-link {
    border-left: 0.2rem solid transparent;
  }
}
@media (min-width: 992px) {
  .navbar .nav-link {
    border-bottom: 0.2rem solid transparent;
  }
}
.navbar .nav-link:hover {
  border-color: #266cb4;
}
.navbar .nav-item.active .nav-link {
  color: #6c757d;
  font-weight: 700;
}
@media (max-width: 991.98px) {
  .navbar .nav-item.active .nav-link {
    border-color: #6c757d;
  }
}
@media (min-width: 992px) {
  .navbar .nav-item.active .nav-link {
    border-color: #6c757d;
  }
}
.navbar-search {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -ms-flex-pack: end;
  justify-content: flex-end;
  position: relative;
}
.navbar-search .form-control {
  width: 10rem;
  display: -ms-inline-flexbox;
  display: inline-flex;
  transition: flex-grow 0.5s ease-in-out, width 0.5s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: flex-grow 0.5s ease-in-out, width 0.5s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
    -ms-flex-positive 0.5s ease-in-out;
}
@media (max-width: 767.98px) {
  .navbar-search .form-control {
    width: 7rem;
  }
}
@media (max-width: 991.98px) {
  .navbar-search .form-control:focus {
    -ms-flex-positive: 1;
    flex-grow: 1;
  }
}
@media (min-width: 768px) {
  .navbar-search .form-control:focus {
    width: 20rem;
  }
}
.navbar-search .form-control {
  padding-right: 2.375rem;
}
.navbar-search .btn {
  width: 2.375rem;
  height: 2.375rem;
  display: -ms-inline-flexbox;
  display: inline-flex;
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.4rem;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: middle;
  align-items: middle;
}
@media (min-width: 992px) {
  .navbar-search {
    max-width: -webkit-fit-content;
    max-width: -moz-fit-content;
    max-width: fit-content;
  }
}
.navbar-nav-icon {
  stroke: currentColor;
  margin-top: -0.2em;
}
svg {
  fill: currentColor;
}
.container-fluid {
  max-width: 1140px;
}
.form-control {
  font-weight: 400;
}
.subnav {
  background: #dee2e6;
  width: 100%;
  position: absolute;
  top: 100%;
  left: 0;
  box-shadow: inset 0 0.5rem 1rem rgba(0, 0, 0, 0.15),
    0 0.5rem 1rem rgba(0, 0, 0, 0.15);
}
@media (max-width: 991.98px) {
  .subnav {
    display: none;
  }
}
.subnav-item {
  width: 25%;
  padding: 0 0.5rem 0.66667rem;
  float: left;
}
.subnav-item:nth-child(4n + 1) {
  padding-left: 0;
}
.subnav-item:nth-child(4n) {
  padding-right: 0;
}
.subnav-item a {
  background: #fff;
  width: 100%;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  display: inline-block;
  padding: 0.5rem;
  font-size: 1.25rem;
  line-height: 1.1;
  border-radius: 2px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: background-color 0.1s linear, transform 0.3s ease-in-out;
  will-change: transform;
}
.subnav-item a:hover {
  background-color: #e9ecef;
  transform: scale(1.1);
  text-decoration: none;
}
.subnav-item small {
  color: #6c757d;
  font-size: 70%;
}
.subnav-item.all-topics a {
  background-color: transparent;
  height: 3.75rem;
  line-height: 2.75rem;
  box-shadow: none;
  border: 1px solid #adb5bd;
}
.subnav-item.all-topics a:hover {
  background-color: #ced4da;
}
.subnav-sort {
  margin-top: -0.2rem;
}
.subnav .row {
  display: block !important;
  width: 100%;
}
/*! CSS Used fontfaces */
@font-face {
  font-family: FontAwesome;
  src: url(https://www.gfu.net/fonts/fontawesome-webfont.eot?v=4.7.0);
  src: url(https://www.gfu.net/fonts/fontawesome-webfont.eot#iefix&v=4.7.0)
      format("embedded-opentype"),
    url(https://www.gfu.net/fonts/fontawesome-webfont.woff2?v=4.7.0)
      format("woff2"),
    url(https://www.gfu.net/fonts/fontawesome-webfont.woff?v=4.7.0)
      format("woff"),
    url(https://www.gfu.net/fonts/fontawesome-webfont.ttf?v=4.7.0)
      format("truetype"),
    url(https://www.gfu.net/fonts/fontawesome-webfont.svg?v=4.7.0#fontawesomeregular)
      format("svg");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

</style>

